import React, { Component } from "react";
import "./footer.css";
import Cookie from "./cookie/cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import MataDecorator from "./../meta-decorator/meta-helmet";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { HttpService } from "../../../services/http/http-service";
import ProgressLoader from "../progress-loading/progress-loader";
// #12692 Defer offscreen images
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followUs:[
        {
          link:"https://twitter.com/ETHolidays1",
          img:"/assets/image/social-media/twitter.png"
        },
        {
          link:"https://www.youtube.com/channel/UCG-Y4mN3UaZ6pU-QAy5PuUw",
          img:"/assets/image/social-media/youtube.png"
        },
        {
          link:"https://www.facebook.com/EthiopianHolidays/",
          img:"/assets/image/social-media/facebook.png"
        },
        {
          link:"https://www.instagram.com/ethiopian_holidays/",
          img:"/assets/image/social-media/instagram.png"
        },
        {
          link:"https://www.linkedin.com/showcase/ethiopian-holidays/",
          img:"/assets/image/social-media/linkedin.png"
        },
        {
          link:"https://t.me/ETHOLIDAYS",
          img:"/assets/image/social-media/telegram.png"
        } 
      ],
      
      paymentImgs:[
        {
          img: "/assets/image/payment/alipay.jpg"
        },
        {
          img:  "/assets/image/payment/American Express.png"
        },
        {
          img: "/assets/image/payment/dinners.jpg"
        },
        {
          img: "/assets/image/payment/flocash.png"
        },
        {
          img:  "/assets/image/payment/master card.png"
        },
        {
          img:  "/assets/image/payment/visa.jpg"
        } 
      ],
      emailModal: false,
      modalText: null,
      isLoading: false,
      modalView: false,
    };
  }
    saveEmail = () => {

        //debugger
    
    var email = document.getElementById("email").value;
    var emailResult = this.validateEmail(email);
    var firstName = document.getElementById("firstName").value;
    var lastName = document.getElementById("lastName").value;
    var checkBox = document.getElementById("checkBox");
    if (firstName == "") {
      document.getElementById("firstName").classList.add("is-invalid");
      return null;
    }
    if (lastName == "") {
      document.getElementById("lastName").classList.add("is-invalid");
      return null;
    }
    if (email == "" || !emailResult) {
      document.getElementById("email").classList.add("is-invalid");
      return null;
    }
    if (!checkBox.checked) {
      this.setState({
        emailModal: true,
        modalText:
          "Please agree to  receive News, Updates and notification from Ethiopian Holidays",
        isLoading: false,
      });
      return null;
    }
    if (emailResult) {
      var requestBody = {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
      };
      this.setState({ isLoading: true, modalView: false });
      HttpService.postEmail(requestBody)
        .then((response) => {
          if (response.data.messageAPI == "Already Subscribed.") {
            this.setState({
              emailModal: true,
              modalText: "Subscription with this email already exists.",
              isLoading: false,
            });
          } else {
            this.setState({
              emailModal: true,
              modalText: "Your have been successfully subscribed.",
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            emailModal: true,
            modalText: "Something went wrong. Please try again.",
            isLoading: false,
          });
        });
    }
  };
  validateEmail = (email) => {
      // const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) { return true }

      else return false;
  };
  closeViewDialog = () => {
    this.setState({ modalView: false });
  };
  closeDialog = () => {
    this.setState({ emailModal: false, modalText: null });
  };
  render() {
    var loading = null;
    if (this.state.isLoading) {
      loading = (
        <div className="Loading-div">
          <div className="Loading">
            <ProgressLoader displayMess="Subscribing your email" />
          </div>
        </div>
      );
    }
    return (
      <footer className="footer-container mt-5">
        {loading}
        <Modal
          show={this.state.emailModal}
          onHide={this.closeDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Email Subscription
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.modalText}</p>
          </Modal.Body>
        </Modal>
        <div id="">
          <Modal
            show={this.state.modalView}
            onHide={this.closeViewDialog}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="closingModal"
          >
            <Modal.Header
              closeButton
              // style={{ backgroundColor: "#60ac5d", color: "white" }}
            >
              <Modal.Title>
                <div id="wrapper-sc">
                  <img
                    class="planeImage-sc"
                    src="https://d22j4fzzszoii2.cloudfront.net/images/1521/2314/EthiopainAirlinesPlane.png"
                    width="633"
                    height="338"
                    alt="Ethiopain Airlines"
                  />
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h2> Email Subscription</h2>
              <div style={{ marginBottom: "3px" }}>
                <form>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    className="form-control mb-3"
                    required
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                    }}
                  />
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    className="form-control mb-3"
                    required
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                    }}
                  />
                  <input
                    type="emial"
                    id="email"
                    placeholder="Email"
                    className="form-control mb-3"
                    required
                    onFocus={function (event) {
                      event.target.classList.remove("is-invalid");
                    }}
                  />
                  <p>
                    <input type="checkbox" id="checkBox" required />
                    <label>
                      I would like to receive news, updates and notifications
                      from Ethiopian Holidays.
                    </label>
                  </p>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="search-btn btn btn-success"
                type="submit"
                onClick={this.saveEmail}
              >
                Subscribe
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="footer-body" style={{zIndex:90}}>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
              <h5 className="text-left">USEFUL LINKS</h5>

              <div className="list-group text-left">
                <a
                  href="https://www.ethiopianairlines.com/home-page"
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  Fly Ethiopian
                </a>
                <a
                  href="https://shebamiles.ethiopianairlines.com/#/about_shebamiles"
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  Shebamiles
                </a>
                <a
                  href="https://www.evisa.gov.et/#/home"
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  e-VISA
                </a>
                <a
                  href="https://www.ethiopianskylighthotel.com/"
                  target="_blank"
                  className="list-group-item list-group-item-action "
                >
                  Ethiopian Skylight Hotel
                </a>
                <a
                  href="https://visitethiopia.travel/"
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  Tourism Ethiopia
                </a>
                <a
                  href="/comment-and-complaint"
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  Comments or Complaints
                </a>
                <a
                  href="/faq"
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  Frequently Asked Questions
                </a>
                <a
                  href="/Exotic-and-timeless-4th-edition.pdf"
                  target="_blank"
                  className="list-group-item list-group-item-action"
                >
                  Brochure
                </a>
                <a></a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
              <h5 className="text-left">CONTACT</h5>
              <div className="list-group text-left">
                <a className="list-group-item list-group-item-action">
                  {" "}
                  ETHolidays@ethiopianairlines.com
                </a>
                <a className="list-group-item list-group-item-action">
                  +251 116179900
                </a>
                <a className="list-group-item list-group-item-action">
                  6787(Local Customer)
                </a>
              </div>
            </div> 
            <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
              <h5 className="text-left">FOLLOW US</h5>
              <div className="list-group text-left">
                <ul className="social-media p-0">
                  <li>
                    {this.state.followUs.map((items)=>
                    <a
                      className="links white-text"
                      href={items.link}
                      target="_blank"
                    >
                      
                      <LazyLoadImage
                                    // className="card-img rounded2"
                                    style={{
                                      cursor: "pointer !important",
                                      maxHeight: "35px",
                                    }}
                                    alt={""}
                                    effect="blur"
                                    src={
                                      window.location.origin + items.img
                                    }
                                  /> 
                      {/* <img
                        src={
                          window.location.origin + items.img
                        }
                      /> */}
                    </a> 
                    
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
              <h5 className="text-left mb-4">SUBSCRIBE</h5>
              <div className="list-group text-left">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onClick={() => {
                      this.setState({ modalView: true });
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary et-btn"
                      type="button"
                      id="button-addon2"
                      onClick={() => {
                        this.setState({ modalView: true });
                      }}
                    >
                      SUBSCRIBE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-5">
              <div className="list-group text-left">
                <ul className="social-media p-0">
                  <li>   
                        {this.state.paymentImgs.map((item)=>
                                              
                        <LazyLoadImage
                          // className="card-img rounded2"
                          className="payment-image  "
                          style={{
                            cursor: "pointer !important",
                            maxHeight: "50px",
                          }}
                          alt={""}
                          effect="blur"
                          src={
                            window.location.origin + item.img
                          }
                        /> 


 ) }       
                  </li>
                </ul>
              </div>
            </div>
            <hr style={{ color: "white", height: "3px" }} />
          </div> 
        </div>
        <Cookie />
        <hr style={{ backgroundColor: "white", maxWidth: "75%" }} />
        <p style={{ textAlign: "center" }}>
          © Ethiopian Holidays All Rights Reserved
        </p>
      </footer>
    );
  }
}

export default Footer;
